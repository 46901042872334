require('jquery.cookie');
document.addEventListener('DOMContentLoaded', function () {

  const window_type = window.innerWidth > 800 ? 'desktop': 'mobile';

  const accessibilityButton = document.querySelector('.' + window_type + '-navigation .acc_btn');
  const accessibilityMenu = document.querySelector('.' + window_type + '-navigation .accessibility_bar');
  const body = document.body;
  const fontBtn = document.querySelector('.' + window_type + '-navigation .acc_text_size');
  const themeBtn = document.querySelector('.' + window_type + '-navigation .acc_contrast');
  const underlineBtn = document.querySelector('.' + window_type + '-navigation .acc_links');

  accessibilityButton.addEventListener('click', function () {
    accessibilityMenu.classList.toggle('accessibility-active');
    body.classList.toggle('accessibility-active');
  });

  themeBtn.addEventListener("click", (e) => {
    e.preventDefault();
    document.body.classList.toggle('active-contrast');
    if ($.cookie('rp_theme')) {
      $.removeCookie('rp_theme');
    } else {
      $.cookie('rp_theme', 'active-contrast', { expires: 365 });
    }
  });

  fontBtn.addEventListener("click", (e) => {
    e.preventDefault();
    document.body.classList.toggle('increase-font');
    if ($.cookie('rp_fonts')) {
      $.removeCookie('rp_fonts');
    } else {
      $.cookie('rp_fonts', 'increase-font', { expires: 365 });
    }
  });

  underlineBtn.addEventListener("click", (e) => {
    e.preventDefault();
    document.body.classList.toggle('underlined-links');
    if ($.cookie('rp_links')) {
      $.removeCookie('rp_links');
    } else {
      $.cookie('rp_links', 'underlined-links', { expires: 365 });
    }
  });


  if ($.cookie('rp_theme')) {
    $('body').addClass('active-contrast');
  } else {
    $('body').removeClass('active-contrast');
  }

  if ($.cookie('rp_links')) {
    $('body').addClass('underlined-links');
  } else {
    $('body').removeClass('underlined-links');
  }

  if ($.cookie('rp_fonts')) {
    $('body').addClass('increase-font');
  } else {
    $('body').removeClass('increase-font');
  }


});
