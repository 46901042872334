require('lightbox2')
require('jquery-ui-bundle')
require('cookie-signature')

$(document).ready(function() {
  var i = 0;
  $('.fnoteBtn').each(function() {
    i = i + 1;
    $(this).attr('href', '#note_' + i);

    $("#footnotes").append('<li id="note_'+ i +'">' + $(this).attr("data-content") + '</li>');
  });

  if ($("#stranica").val() >= $("#max_stranica").val()){
    $("#next").attr("disabled", true);
  }

  if ($("#stranica").val() <= 1){
    $("#previous").attr("disabled", true);
  }

  $('#next').on('click', function (event) {
    $("#stranica").val(parseInt($("#stranica").val())+1);
    $.ajax({
      type : 'GET',
      url : window.location.pathname,
      data: {"stranica" : $("#stranica").val()},
      success : function(responseData, textStatus, jqXHR) {
        $("#related_news_container").html(responseData);
        if ($("#stranica").val() >= $("#max_stranica").val()){
          $("#next").attr("disabled", true);
        }
        $("#previous").attr("disabled", false);
      },
      error : function(responseData, textStatus, errorThrown) {
      }
    });

  });

  $('#previous').on('click', function (event) {
    $("#stranica").val(parseInt($("#stranica").val())-1);
    $.ajax({
      type : 'GET',
      url : window.location.pathname,
      data: {"stranica" : $("#stranica").val()},
      success : function(responseData, textStatus, jqXHR) {
        $("#related_news_container").html(responseData);
        if ($("#stranica").val() <= 1){
          $("#previous").attr("disabled", true);
        }
        $("#next").attr("disabled", false);
      },
      error : function(responseData, textStatus, errorThrown) {
      }
    });

  });
});


