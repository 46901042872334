/* eslint no-console:0 */
const css = require.context("../../assets/stylesheets")
css.keys().forEach(css)

const js = require.context("../../assets/javascripts")
js.keys().forEach(js)

const componentRequireContext = require.context("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

$(document).ready(function() {

  /*$(".more a").click(function(){
    box = $(this).attr("rel");
    $("." + box).css("height", "100%");
    return false;
  });*/

  $(".hamburger_nation_bzr a").click(function(e){
    e.preventDefault();
    if($(this).text() == "X") {
      $(this).text("☰");
    } else {
      $(this).text("X");
    }
    $("#main_navigation").toggle('slow');

    if ($('#accessibility_bar').hasClass('accessibility-active')) {
      $('#accessibility_bar').removeClass('accessibility-active');
    }
  })

});
